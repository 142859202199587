import "react-toastify/dist/ReactToastify.css";
import "./Hero.css";
import React, { useRef, useState } from "react";
import { Button, LoadingButton, TextInput } from "@mantine/core";
import { ToastContainer, toast } from "react-toastify";

const Hero = () => {
  const [submitted, setSubmitted] = useState(false);
  const [details, setDetails] = useState({
    email: "",
  });
  const [emailError, setEmailError] = useState("");

  const validateEmail = (email) => {
    const regex =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  };

  const PostData = async (e) => {
    e.preventDefault();

    const { email } = details;

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      toast.error("Please enter a valid email address.");
      return;
    }

    const res = await fetch(
      "https://fomo-b8810-default-rtdb.firebaseio.com/emails.json",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      }
    );

    if (res.ok) {
      setDetails({ email: "" });
      setSubmitted(true);
      toast.success("Email submitted successfully!");
    }
  };

  return (
    <section id="hero">
      <div className="container">
        <div className="hero-contents d-flex flex-column gap-5 gap-md-0 flex-md-row align-items-center">
          <div className="hero-left-side">
            <h1 className="get-smarter-text ">
              Get smarter about <br />
            </h1>
            <h1 className="get-smarter-text">
              <span className="nft-text m-0">Your NFTs</span>{" "}
              <span className="investment-text m-0"></span>
            </h1>
            <p className="newsletter-text">
              Get your <b>FREE 1-year</b> digital magazine subscription now
              {" "}
              <br className="d-none d-lg-block" /> to gain an edge in the NFT market
            </p>

            <div className="d-flex justify-content-between align-items-center hero-input-container">
              <input
                className="hero-input"
                size="xl"
                type="email"
                placeholder="Enter email address..."
                value={details.email}
                onChange={(e) => {
                  setDetails({ ...details, email: e.target.value });
                  setEmailError("");
                }}
              />
              <Button
                size="xl"
                onClick={PostData}
                style={{
                  backgroundColor: "#e27c23",
                }}
              >
                Join Free
              </Button>
            </div>

            <p className="read-by-nft-text">Read by NFT whales from</p>
            <div className="hero-bottom-image-group">
              <img className="w-100" src="/images/binance.png" alt="" />
              <img className="trust w-100" src="/images/trust.png" alt="" />
              <img className="w-100" src="/images/metamask.png" alt="" />
            </div>
          </div>
          <div className="right-side mx-auto">
            <img
              className="d-block mx-auto"
              src="/images/laser-eyes.gif"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default Hero;
