import "react-toastify/dist/ReactToastify.css";
import "./Subscribe.css";
import React, { useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

const Subscribe = () => {
  const [submitted, setSubmitted] = useState(false);
  const [details, setDetails] = useState({
    message: "",
  });

  const PostData = async (e) => {
    e.preventDefault();

    if (!details.message) {
      toast.error("Please enter a message"); // display toast message
      return;
    }

    const res = await fetch(
      "https://fomo-b8810-default-rtdb.firebaseio.com/Message.json",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message: details.message,
        }),
      }
    );
    setSubmitted(true);
    toast.success("Submitted");
  };

  return (
    <section id="subscribe">
      <div className="container">
        <div className="subscribe-contents">
          <h1>Subscribe to our newsletter</h1>
          <p className="top-p">
            Be the first to know about the biggest stories as they break
          </p>

          <div className="input-content">
            <input
              name="message"
              onChange={(e) => setDetails({ message: e.target.value })}
            />
            <button onClick={PostData} type="submit" value="Send">
              Send
            </button>
          </div>

          <p className="bottom-p">
            By clicking Subscribe, you indicate that you agree to Hypnosis Terms
            and Conditions and Data Use Privacy Policy
          </p>
        </div>
      </div>
    </section>
  );
};

export default Subscribe;
